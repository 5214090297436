import { Routes } from '@angular/router';

import {
  PostsNewestResolver,
  PostResolver,
  PostsFeaturedResolver,
} from '@shared/resolvers/post.resolver';
import { ChannelResolver } from '@shared/resolvers/channel.resolver';

import { ChannelExistsGuard } from '@shared/guards/channel-exists.guard';

import { LoginGuard } from '@shared/guards/login.guard';
import { NotLoggedInGuard } from '@shared/guards/not-login.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../frontend/intro/intro.module').then((m) => m.IntroModule),
    pathMatch: 'full',
    canActivate: [NotLoggedInGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('../frontend/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'error/:errorNumber',
    loadChildren: () => import('../frontend/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../frontend/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'my-channels/:chanId',
    loadChildren: () =>
      import('../frontend/manage-channels/manage-channels.module').then(
        (m) => m.ManageChannelsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'my-channels',
    loadChildren: () =>
      import('../frontend/manage-channels/manage-channels.module').then(
        (m) => m.ManageChannelsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'view-classifications-categories',
    loadChildren: () =>
      import(
        '../frontend/view-classifications-categories/view-classifications-categories.module'
      ).then((m) => m.ViewClassificationsCategoriesModule),
  },
  {
    path: 'feed',
    loadChildren: () => import('../frontend/feed/feed.module').then((m) => m.FeedModule),
    data: { preloadType: 'feed' },
    resolve: {
      // TODO make new resolver that returns only videos for the feed
      newPosts: PostsNewestResolver,
      featuredPosts: PostsFeaturedResolver,
    },
  },
  {
    path: 'browse',
    loadChildren: () => import('../frontend/landing/landing.module').then((m) => m.LandingModule),
    data: { preloadType: 'newest' },
    resolve: {
      newPosts: PostsNewestResolver,
      featuredPosts: PostsFeaturedResolver,
    },
  },
  {
    path: 'browse/:classification/:category/:kind',
    loadChildren: () =>
      import('../frontend/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'p/:postShortId',
    loadChildren: () =>
      import('../frontend/view-post/view-post.module').then((m) => m.ViewPostModule),
    resolve: {
      postdata: PostResolver,
    },
  },
  {
    path: 'post/:postId',
    loadChildren: () =>
      import('../frontend/view-post/view-post.module').then((m) => m.ViewPostModule),
    resolve: {
      postdata: PostResolver,
    },
  },
  {
    path: 'post/:postId/:postName',
    loadChildren: () =>
      import('../frontend/view-post/view-post.module').then((m) => m.ViewPostModule),
    resolve: {
      postdata: PostResolver,
    },
  },
  {
    path: 'profile',
    loadChildren: () => import('../frontend/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'user/:userId/profile',
    loadChildren: () => import('../frontend/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'user/:userId/profile/delete/:token',
    loadChildren: () =>
      import('../frontend/delete-user/delete-user.module').then((m) => m.DeleteUserModule),
  },
  // {
  //   path: 'info/promo',
  //   loadChildren: () => import('../frontend/info-pages/promo-100/promo-100.module').then(m => m.PromoModule),
  // },
  {
    path: 'info',
    loadChildren: () =>
      import('../frontend/info-pages/info-pages.module').then((m) => m.InfoPagesModule),
  },
  {
    path: 'search',
    loadChildren: () => import('../frontend/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'category/:categoryName',
    loadChildren: () =>
      import('../frontend/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'category',
    loadChildren: () =>
      import('../frontend/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'c/:channelShortId',
    loadChildren: () =>
      import('../frontend/view-channel/view-channel.module').then((m) => m.ViewChannelModule),
    resolve: {
      channeldata: ChannelResolver,
    },
  },
  {
    path: 'channel/:channelId',
    loadChildren: () =>
      import('../frontend/view-channel/view-channel.module').then((m) => m.ViewChannelModule),
    resolve: {
      channeldata: ChannelResolver,
    },
    canActivateChild: [ChannelExistsGuard],
  },
  {
    path: 'channel/:channelId/:channelUrlName',
    loadChildren: () =>
      import('../frontend/view-channel/view-channel.module').then((m) => m.ViewChannelModule),
    resolve: {
      channeldata: ChannelResolver,
    },
    canActivateChild: [ChannelExistsGuard],
  },
  {
    path: 'playlist/:playlistId',
    loadChildren: () =>
      import('../frontend/view-playlist/view-playlist.module').then((m) => m.ViewPlaylistModule),
  },
  {
    path: 'embed',
    loadChildren: () => import('../frontend/embed/embed.module').then((m) => m.EmbedPlayerModule),
  },
];
